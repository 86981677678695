import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AgentlistapiService } from "src/app/services/agentlistapi.service";
import { TokenService } from "src/app/services/token.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;
  visiblePassword: boolean = false;
  result: any;
  siteName = environment.siteName;
  constructor(
    private fb: FormBuilder,
    private loginService: AgentlistapiService,
    private tokenService: TokenService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.LoginForm = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  Login() {
    if (!this.LoginForm.valid) {
      return;
    }

    this.loginService.LoginApi(this.LoginForm.value).subscribe(
      (resp: any) => {
        
        if (resp.errorCode === 0) {
          this.showNotification("Successfully Login", 0);
          this.tokenService.setToken(resp.result[0].token);
          this.tokenService.setSiteName(environment.siteName);
          this.tokenService.setUserInfo(resp.result[0]);
          this.tokenService.setWLID(resp.result[0].WLID);
          this.result = resp.errorDescription;
          this.LoginForm.reset();
          this.router.navigate(['dasboard']);
        } else {
          this.showNotification(resp.errorDescription, 1);
          if (!resp.errorDescription) {
            resp.errorDescription = "Username or password is wrong";
          }
          this.result = resp.errorDescription;
          
        }
      },
      (err) => {
        this.showNotification("Invalid Credential", 1);
        console.log("something wents wrong");
      }
    );
  }


  showNotification(type, errorCode) {
    if (errorCode == 0) {
      this.toastr.info(
        `<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ${type} .`,
        "",
        {
          timeOut: 3000, 
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-success alert-with-icon",
          positionClass: "toast-top-right",
        }
      );
    } else {
      this.toastr.info(
        `<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ${type} .`,
        "",
        {
          timeOut: 3000, 
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-danger alert-with-icon",
          positionClass: "toast-top-right",
        }
      );
    }
  }
  

  showPassword() {
    this.visiblePassword = !this.visiblePassword;
  }
}

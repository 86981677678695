<nav class=" navbar navbar-expand-lg navbar-absolute navbar-transparent">
  <div class=" container-fluid">
    <div class=" navbar-wrapper">
      <div class=" navbar-toggle d-inline">
        <button class=" navbar-toggler" type="button" (click)="sidebarToggle()">
          <span class=" navbar-toggler-bar bar1"> </span>
          <span class=" navbar-toggler-bar bar2"> </span>
          <span class=" navbar-toggler-bar bar3"> </span>
        </button>
      </div>
      <a class=" navbar-brand" routerLink="/"> <img class="site-logo"
          src="assets/img/logo/{{siteName}}.webp" alt="logo"> </a>
    </div>
    <button aria-label="Toggle navigation" class=" navbar-toggler" (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" id="navigation" type="button">
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
    </button>
    <div class=" navbar-collapse" [ngbCollapse]="isCollapsed" id="navigation">
      <ul class=" navbar-nav ml-auto">
        <li class=" search-bar input-group">
          <button class=" btn btn-link" data-target="#searchModal" data-toggle="modal" (click)="open(content)"
            id="search-button">
            <i class=" tim-icons icon-zoom-split"> </i>
            <span class=" d-lg-none d-md-block"> Search </span>
          </button>
        </li>
        <li class=" nav-item" *ngIf="accountinfo">
          <a class=" nav-link d-flex align-items-center">
            <div class=" notification d-none d-lg-block d-xl-block"></div>
            <i class="icon-single-02 tim-icons"> </i>
            <p class="d-lg-none">User :<b>{{accountinfo?.username}}</b> </p>
            <p class="d-none-samll m-1 f-bold"><b>{{accountinfo?.username}}</b> </p>
          </a>
        </li>
        <li class=" nav-item d-flex align-items-center">
          <a href="{{AgentListLink}}" target="_blank" class=" nav-link d-flex align-items-center">
            <div class=" notification d-none d-lg-block d-xl-block"></div>
            <i class="fas fa-external-link-alt"></i>
            <span class=" d-lg-none d-md-block agentlist"> Open AgentList </span>
          </a>
        </li>
        <li class=" nav-item" ngbDropdown>
          <a class=" nav-link" data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>
            <div class=" photo">
              <img alt="Profile Photo" src="assets/img/anime3.png" />
            </div>
            <b class=" caret d-none d-lg-block d-xl-block"> </b>
            <p class=" d-lg-none">Log out</p>
          </a>
          <ul class=" dropdown-navbar" ngbDropdownMenu>
            <!-- <li class=" nav-link">
              <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                Profile
              </a>
            </li> -->
            <li class=" nav-link">
              <a class=" nav-item" href="javascript:void(0)" (click)="openchangepass(changepass)" ngbDropdownItem>
                Change Password
              </a>
            </li>
            <li class=" dropdown-divider"></li>
            <li class=" nav-link">
              <a class=" nav-item" href="javascript:void(0)" (click)="confirmationpop(confirmation)" ngbDropdownItem>
                Log out
              </a>
            </li>
          </ul>
        </li>
        <li class=" separator d-lg-none"></li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #content let-modal>
  <div class=" modal-header">
    <input class=" form-control" id="inlineFormInputGroup" placeholder="SEARCH" (input)="onSearchChange($event)"
      type="text" />

    <button aria-label="Close" class=" close" data-dismiss="modal" type="button" (click)="modal.dismiss('Cross click')">
      <i class=" tim-icons icon-simple-remove"> </i>
    </button>
  </div>

</ng-template>

<ng-template #changepass let-modal>

  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>

    <i class="fas fa-window-close" (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <div class="login-form">
      <form [formGroup]="ChangePwdForm" >
        <div class=" row">
          <div class=" col-md-12">
            <div class=" form-group"><label> Old Password </label>
              <input formControlName="oldPassword" class=" form-control form-control1" placeholder="Old Password" [type]="!visiblePassword2 ? 'password' : 'text'">
              <span (click)="showPassword3()" [ngClass]="{'fa-eye': visiblePassword2,'fa-eye-slash': !visiblePassword2}" class="fa fa-eye"></span>
            </div>
            <div id="oldPasswordErrorText" class="error-text" *ngIf="oldPassword?.errors?.required && f.oldPassword.touched">
              Old Password is required.</div>
          </div>
        </div>
        <div class=" row">
          <div class="col-md-12">
            <div class=" form-group"><label> New Password </label>
              <input formControlName="newpassword" [type]="!visiblePassword ? 'password' : 'text'" class=" form-control form-control1" placeholder=" New Password"
                type="password">
                <span (click)="showPassword()" [ngClass]="{'fa-eye': visiblePassword,'fa-eye-slash': !visiblePassword}" class="fa fa-eye"></span>
            </div>
            <div id="newPasswordErrorText" class="error-text" *ngIf="newpassword?.errors?.required && f.newpassword.touched">
              New Password is required</div>
            <div id="newPasswordErrorText" class="error-text"
              *ngIf="newpassword?.errors?.minlength">The New Password field must be at least 5 characters in length.</div>
            <div id="newPasswordErrorText" class="error-text" *ngIf="newpassword?.errors?.minlength">The New
              Password field must be at least 4 characters in length..</div>
          </div>
        </div>
        <div class=" row">
          <div class=" col-md-12">
            <div class=" form-group"><label> Confirm Password </label>
              <input formControlName="confirmpassword" [type]="!visiblePassword1 ? 'password' : 'text'" class=" form-control form-control1" placeholder="Confirm Password"
                type="password">
                <span (click)="showPassword2()" [ngClass]="{'fa-eye': visiblePassword1,'fa-eye-slash': !visiblePassword1}" class="fa fa-eye"></span>
               
            </div>
            <div>
              <span id="newPasswordConfirmErrorText" class="error-text"
                    *ngIf="confirmpassword?.errors?.required && f.confirmpassword.touched">
                    Confirm Password is required.</span>
                <span id="newPasswordConfirmErrorText" class="error-text"
                    *ngIf="confirmpassword?.errors?.mustMatch">New password and confirm password should be same !</span>
            </div>
          </div>
        </div>
        <div class="form-group"><button (click)="ChangePwdForm.invalid?'':ChangePwd();modal.close('Close click')" data-bs-dismiss="modal" class="btn btn-primary btn-block">Change
            Password</button></div>
      </form>
    </div>
  </div>

</ng-template>
<ng-template #confirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>

    <i class="fas fa-window-close" (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to Logout ?</p>
  </div>
  <div class="t-center">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Close</button>
    <button type="button" class="btn btn-outline-dark" (click)="Logout();modal.close('Close click')">Yes</button>
  </div>

</ng-template>
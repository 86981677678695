<div class="wrapper">
  <div class="sidebar">
    <div class="logo">
      <a href="https://www.creative-tim.com?ref=bda-auth-layout-sidebar-logo" class="simple-text logo-mini">
        <div class="logo-img">
          <img src="../../../assets/img/favicon/favicon.ico" />
        </div>
      </a>
      <a href="https://www.creative-tim.com?ref=bda-auth-layout-sidebar-logo" class="simple-text logo-normal">
        الإبداعية تيم
      </a>
    </div>
    <div class="sidebar-wrapper">
      <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item">
          <a [routerLink]="[menuItem.path]">
            <i class="tim-icons  {{ menuItem.icon }}"></i>
            <p>{{ menuItem.rtlTitle }}</p>
          </a>
        </li>
      </ul>
    </div>

  </div>
  <div class="main-panel">
    <nav class=" navbar navbar-expand-lg navbar-absolute navbar-transparent">
      <div class=" container-fluid">
        <div class=" navbar-wrapper">
          <div class=" navbar-toggle d-inline">
            <button class=" navbar-toggler" type="button" (click)="sidebarToggle()">
              <span class=" navbar-toggler-bar bar1"> </span>
              <span class=" navbar-toggler-bar bar2"> </span>
              <span class=" navbar-toggler-bar bar3"> </span>
            </button>
          </div>
          <a class=" navbar-brand" href="javascript:void(0)"> ار تي ال </a>
        </div>
        <button aria-label="Toggle navigation" class=" navbar-toggler" (click)="collapse()"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" id="navigation" type="button">
          <span class=" navbar-toggler-bar navbar-kebab"> </span>
          <span class=" navbar-toggler-bar navbar-kebab"> </span>
          <span class=" navbar-toggler-bar navbar-kebab"> </span>
        </button>
        <div class=" navbar-collapse" [ngbCollapse]="isCollapsed" id="navigation">
          <ul class=" navbar-nav mr-auto">
            <li class=" search-bar input-group">
              <button class=" btn btn-link" data-target="#searchModal" data-toggle="modal" (click)="open(content)"
                id="search-button">
                <i class=" tim-icons icon-zoom-split"> </i>
                <span class=" d-lg-none d-md-block"> بحث </span>
              </button>
            </li>
            <li class=" nav-item" ngbDropdown>
              <a class=" nav-link" data-toggle="dropdown" ngbDropdownToggle>
                <div class=" notification d-none d-lg-block d-xl-block"></div>
                <i class=" tim-icons icon-sound-wave"> </i>
                <p class=" d-lg-none">إخطارات</p>
              </a>
              <ul class=" dropdown-menu-right dropdown-navbar" ngbDropdownMenu>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    ورد مايك جون على بريدك الإلكتروني
                  </a>
                </li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    لديك 5 مهام أخرى
                  </a>
                </li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    صديقك مايكل في المدينة
                  </a>
                </li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    إشعار آخر
                  </a>
                </li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    واحدة أخرى
                  </a>
                </li>
              </ul>
            </li>
            <li class=" nav-item" ngbDropdown>
              <a class=" nav-link" data-toggle="dropdown" ngbDropdownToggle>
                <div class=" photo">
                  <img alt="Profile Photo" src="assets/img/anime3.png" />
                </div>
                <b class=" caret d-none d-lg-block d-xl-block"> </b>
                <p class=" d-lg-none">الخروج</p>
              </a>
              <ul class=" dropdown-navbar" ngbDropdownMenu>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    الملف الشخصي
                  </a>
                </li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    الإعدادات
                  </a>
                </li>
                <li class=" dropdown-divider"></li>
                <li class=" nav-link">
                  <a class=" nav-item" href="javascript:void(0)" ngbDropdownItem>
                    الخروج
                  </a>
                </li>
              </ul>
            </li>
            <li class=" separator d-lg-none"></li>
          </ul>
        </div>
      </div>
    </nav>
    <router-outlet></router-outlet>
    <footer class=" footer">
      <div class=" container-fluid">
        <ul class=" nav">
          <li class=" nav-item">
            <a class=" nav-link" href="https://www.creative-tim.com?ref=bda-auth-layout-footer">
              تيم الإبداعية
            </a>
          </li>
          <li class=" nav-item">
            <a class=" nav-link" href="https://www.creative-tim.com/about-us?ref=bda-auth-layout-footer">
              معلومات عنا
            </a>
          </li>
          <li class=" nav-item">
            <a class=" nav-link" href="http://blog.creative-tim.com?ref=bda-auth-layout-footer"> مدونة </a>
          </li>
        </ul>
        <div class=" copyright">
          &copy; {{ test | date: "yyyy" }} مصنوع من
          <i class=" tim-icons icon-heart-2"> </i> بواسطة
          <a href="https://www.creative-tim.com?ref=bda-auth-layout-footer" target="_blank"> تيم الإبداعية </a>
          من أجل شبكة أفضل.
        </div>
      </div>
    </footer>

  </div>
</div>
<div class=" fixed-plugin">
  <div class=" show-dropdown" ngbDropdown>
    <a data-toggle="dropdown" ngbDropdownToggle>
      <i class=" fa fa-cog fa-2x"> </i>
    </a>
    <ul ngbDropdownMenu>
      <li class=" header-title">خلفية الشريط الجانبي</li>
      <li class=" adjustments-line">
        <a class=" switch-trigger background-color" href="javascript:void(0)">
          <div class=" badge-colors text-center">
            <span class=" badge filter badge-danger" [ngClass]="{'active':sidebarColor==='red'}"
              (click)="changeSidebarColor('red')">
            </span>
            <span class=" badge filter badge-primary" data-color="primary"
              [ngClass]="{'active':sidebarColor==='primary'}" (click)="changeSidebarColor('primary')">
            </span>
            <span class=" badge filter badge-info" [ngClass]="{'active':sidebarColor==='blue'}"
              (click)="changeSidebarColor('blue')"> </span>
            <span class=" badge filter badge-success" [ngClass]="{'active':sidebarColor==='green'}"
              (click)="changeSidebarColor('green')">
            </span>
          </div>
          <div class=" clearfix"></div>
        </a>
      </li>
      <li class=" adjustments-line text-center color-change">
        <span class=" color-label"> وضع الضوء </span>
        <span class=" badge light-badge mr-2" (click)="changeDashboardColor('white-content')"> </span>
        <span class=" badge dark-badge ml-2" (click)="changeDashboardColor('black-content')"> </span>
        <span class=" color-label"> وضع الظلام</span>
      </li>
      <li class=" button-container">
        <a class=" btn btn-primary btn-block btn-round"
          href="https://www.creative-tim.com/product/black-dashboard-angular?ref=bda-auth-layout-fixed-plugin"
          target="_blank">
          التحميل الان
        </a>
        <a class=" btn btn-default btn-block btn-round"
          href="https://demos.creative-tim.com/black-dashboard-angular/#/documentation/tutorial?ref=bda-auth-layout-fixed-plugin"
          target="_blank">
          كابل بيانات
        </a>
      </li>
      <li class=" header-title">شكرا لك على 95 سهم!</li>
      <li class=" button-container text-center">
        <button class=" btn btn-round btn-info" id="twitter">
          <i class=" fab fa-twitter"> </i> · 45
        </button>
        <button class=" btn btn-round btn-info" id="facebook">
          <i class=" fab fa-facebook-f"> </i> · 50
        </button>
        <br />

        <br />

        <a aria-label="Star ntkme/github-buttons on GitHub" class=" github-button" data-icon="octicon-star"
          data-show-count="true" data-size="large"
          href="https://github.com/creativetimofficial/black-dashboard-angular?ref=bda-auth-layout-fixed-plugin">
          نجمة
        </a>
      </li>
    </ul>
  </div>
</div>

<ng-template #content let-modal>
  <div class=" modal-header">
    <input class=" form-control" id="inlineFormInputGroup" placeholder="بحث" type="text" />

    <button aria-label="Close" class=" close" data-dismiss="modal" type="button" (click)="modal.dismiss('Cross click')">
      <i class=" tim-icons icon-simple-remove"> </i>
    </button>
  </div>

</ng-template>
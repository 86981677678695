import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from "src/app/services/shared-data.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MustMatch } from "src/app/helpers/must-match.validator";
import { AgentlistapiService } from "src/app/services/agentlistapi.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  siteName = environment.siteName
  AgentListLink = environment.AgentListLink
  accountinfo:any
  ChangePwdForm: FormGroup;
  userID :any
  visiblePassword: boolean = false;
  visiblePassword1: boolean = false;
  visiblePassword2: boolean = false;

  public isCollapsed = true;

  closeResult: string;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private modalService: NgbModal,
    private searchService: SharedDataService,
    private fb: FormBuilder,
    private loginservice :AgentlistapiService,
    private toastr:ToastrService
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.accountinfo = JSON.parse(localStorage.getItem('UserInfo'))
    this.userID = this.accountinfo.userId
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
   updateColor = () => {
   var navbar = document.getElementsByClassName('navbar')[0];
     if (window.innerWidth < 993 && !this.isCollapsed) {
       navbar.classList.add('bg-white');
       navbar.classList.remove('navbar-transparent');
     } else {
       navbar.classList.remove('bg-white');
       navbar.classList.add('navbar-transparent');
     }
   };
  ngOnInit() {
    this.initChangePwdForm();
    window.addEventListener("resize", this.updateColor);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe(event => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
    
  }
  initChangePwdForm() {
    this.ChangePwdForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newpassword: ['', [Validators.required, Validators.minLength(4)]],
      confirmpassword: ['', Validators.required],
      userID: [this.userID]
    }, {
      validator: MustMatch('newpassword', 'confirmpassword')
    })

  }
  get f() {
    return this.ChangePwdForm.controls;
  }

  get oldPassword() { return this.ChangePwdForm?.get('oldPassword') };
  get newpassword() { return this.ChangePwdForm?.get('newpassword') };
  get confirmpassword() { return this.ChangePwdForm?.get('confirmpassword') };

  ChangePwd() {
    // console.log(this.ChangePwdForm)
    if (!this.ChangePwdForm.valid) {
      return;
    }
    this.loginservice.changePassword(this.ChangePwdForm.value).subscribe((resp:any) => {      
      if (resp.errorCode == 0) {
        this.showNotification("Password updated successfull", 0);
        this.resetFrom();
        localStorage.clear()
        this.router.navigateByUrl('/login');
      }
      else {
        this.showNotification(resp.errorDescription, 1);        
      }
    },
    (err) => {
      this.showNotification("Invalid Credential", 1);
    }
  )
  }
  resetFrom() {
    // this.ChangePwdForm.reset();
    this.initChangePwdForm();
  }

  showNotification(type, errorCode) {
    if (errorCode == 0) {
      this.toastr.info(
        `<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ${type} .`,
        "",
        {
          timeOut: 3000, 
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-success alert-with-icon",
          positionClass: "toast-top-right",
        }
      );
    } else {
      this.toastr.info(
        `<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ${type} .`,
        "",
        {
          timeOut: 3000, 
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-danger alert-with-icon",
          positionClass: "toast-top-right",
        }
      );
    }
  }

  onSearchChange(event: any) {
    const searchTerm = event.target.value;
    this.searchService.changeSearchTerm(searchTerm);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }

    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function() {
        mainPanel.style.position = "";
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const html = document.getElementsByTagName('html')[0];
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName("html")[0];

    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      html.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function() {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function() {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (html.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (html.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function() {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function() {
        //asign a function
        html.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function() {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      html.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }



  open(content) {
    this.modalService.open(content, {windowClass: 'modal-search'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  confirmationpop(confirmation){
    this.modalService.open(confirmation, {windowClass: 'confirmation-modal'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openchangepass(changepass) {
    this.modalService.open(changepass, {windowClass: 'change-pass'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  Logout(){
    localStorage.clear()
    this.router.navigate(['/login'])
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  showPassword() {
    this.visiblePassword = !this.visiblePassword;
  }
  showPassword2() {
    this.visiblePassword1 = !this.visiblePassword1;
  }
  showPassword3() {
    this.visiblePassword2 = !this.visiblePassword2;
  }
  ngOnDestroy(){
     window.removeEventListener("resize", this.updateColor);
  }
}

import { Component, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "black-dashboard-angular";
  siteName = environment.siteName;

  constructor(
    @Inject(DOCUMENT) private document: Document,

  ){
    let bodytag = document.getElementsByTagName("BODY")[0];
    bodytag.classList.add(this.siteName);
    let favicon = this.document.querySelector('#appIcon') as HTMLLinkElement;
    favicon.href = "assets/img/favicon/" + this.siteName + ".ico";
    // this.loadStyle('assets/theme/' + this.siteName + '.css?v=89852024');

  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AgentlistapiService {

  WLID : any
  accountinfo:any
  baseUrl = 'https://api2.streamingtv.fun:3479/api'
  
  constructor(private httpClient: HttpClient,private tokenservice:TokenService) {
    this.WLID = this.tokenservice.getWLIDDetail()

    // this.accountinfo = JSON.parse(localStorage.getItem('UserInfo'))
    // if(this.accountinfo){
    //   this.WLID = this.accountinfo.WLID
    // }
    
   }

  getAgentlist(WLID:any){
    return this.httpClient.get(`${this.baseUrl}/getAgentList/${WLID}`)
  }
  deleteAgentlist(ID:any) {
    return this.httpClient.delete(`${this.baseUrl}/deleteAgent/${ID}`)
  }
  Updateagent(data:any,agID:any){
    return this.httpClient.put(`${this.baseUrl}/Updateagents/${agID}`,data)
  }
  addnewagent(id:any){
    return this.httpClient.post(`${this.baseUrl}/agents`,id)
  }
  LoginApi(data){
    return this.httpClient.post(`${this.baseUrl}/LoginAgent`,data)
  }

  proxylink(data){
    return this.httpClient.post(`${this.baseUrl}/proxylink`,data)
  }
  GetProxyData(){
    return this.httpClient.get(`${this.baseUrl}/getproxyLink/${this.WLID}`)
  }
  deleteproxyData(ID:any){
    return this.httpClient.delete(`${this.baseUrl}/deleteproxyLink/${ID}`)
  }

  changePassword(data: any) {
    return this.httpClient.put(`${this.baseUrl}/changepassword-agentList`, data);
  }
  
}

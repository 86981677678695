<div id="root">
    <div class="ltr dark" dir="ltr">
        <div class="loginBox"><svg xmlns="http://www.w3.org/2000/svg" width="376" height="317" fill="none"
                class="position-fixed left bottom-0">
                <path fill="#2B87F3"
                    d="M98 59.596c-28-61.6-77-63.667-98-57v314l375-1c2.4-80.8-78-135.333-118-150-41.333-9.667-131-44.4-159-106">
                </path>
            </svg><svg xmlns="http://www.w3.org/2000/svg" width="235" height="255" fill="none"
                class="position-fixed end-0 top">
                <path fill="#62CFF1" fill-opacity="0.5"
                    d="M68.1 80.835C29.568 58.825 50.747 26.4 66.154 12.937L221.33 57.304l-47.588 170.031c-54.155 25.255-83.028 7.944-90.695-3.868-5.774-13.59 7.306-40.363 14.567-52.051 19.963-22.85 22.57-48.563 21.379-58.563-6.44-19.763-36.613-29.58-50.894-32.018">
                </path>
                <path fill="#62CFF1"
                    d="M88 72C43.2 60 55.333 19 67 0l168 1v196c-47.2 43.2-81 33.333-92 23-9.6-12.8-4-45.333 0-60 13.6-30.4 9-58.667 5-69-12-19.2-45-20.667-60-19">
                </path>
            </svg>
            <div class="h-100 container-fluid">
                <div class="justify-content-center align-items-center h-100 row">
                    <div class="loginContainer col-lg-12">
                        <div class="p-4 d-flex justify-content-center gap-2">
                            <img src="../../../assets/img/logo/play25.webp" alt="">
                        </div>
                        <div class="card">
                            <div class="p-4 m-1 card-body">
                                <h5 class="mb-0">Login</h5>
                                <form [formGroup]="LoginForm" (ngSubmit)="Login()">
                                    <div class="mb-3"><label for="username" class="form-label">User Name</label>
                                        <input name="username" type="text" formControlName="userName"
                                            class="form-control" value="">
                                    </div>
                                    <div class="mb-3"><label for="password" class="form-label">Password</label>
                                        <input name="password" type="password" [type]="!visiblePassword ? 'password' : 'text'" formControlName="password"
                                            class="form-control" value="">
                                            <span (click)="showPassword()" [ngClass]="{'fa-eye': visiblePassword,'fa-eye-slash': !visiblePassword}" class="fa fa-eye"></span>
                                    </div>

                                    <div class="mb-3"><button type="submit"
                                            class="me-2 btn btn-primary w-100">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
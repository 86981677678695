import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "icon-chart-pie-36",
    class: "",
  },
  {
    path: "/notifications",
    title: "Notifications",
    icon: "icon-bell-55",
    class: "",
  },

  {
    path: "/user",
    title: "User Profile",
    icon: "icon-single-02",
    class: "",
  },
  {
    path: "/tables",
    title: "Table List",
    icon: "icon-puzzle-10",
    class: "",
  },
];



@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  siteName = environment.siteName
  selectedstatus: any = "Active";

  closeResult: string;
levelHierarchy = [
  { id: 2, name: 'ALL', icon: "icon-single-02", path: "/dashboard" },
  // { id: 2, name: 'WhiteLabel', icon: "icon-single-02", path: "/dashboard/2" },
  { id: 3, name: 'Admin', icon: "icon-single-02", path: "/dashboard/3" },
  { id: 4, name: 'Sub Admin', icon: "icon-single-02" , path: "/dashboard/4"},
  { id: 5, name: 'Super Master' , icon: "icon-single-02", path: "/dashboard/5"},
  { id: 6, name: 'Master', icon: "icon-single-02", path: "/dashboard/6" },
  // { id: 7, name: 'Agent', icon: "icon-single-02" , path: "/dashboard/7"},
  { id: 0, name: 'Support Agents', icon: "icon-single-02" , path: "/dashboard/0"},
  { id: 11, name: 'Quick Agent', icon: "icon-single-02" , path: "/dashboard/11"}
]

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
  
}

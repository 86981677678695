import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export const AUTH_TOKEN = 'AuthToken';
export const USER_INFO = 'UserInfo';
export const Site_name = 'SiteName';

@Injectable({
  providedIn: 'root'
})


export class TokenService {

  constructor(private cookieService: CookieService) { }
  setToken(token) {
    this.cookieService.set(AUTH_TOKEN, token);
  }
  setUserInfo(userInfo) {
    this.cookieService.set(USER_INFO, JSON.stringify(userInfo));
    localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
    
  }
  setWLID(WLID){
    localStorage.setItem('WLID',WLID);
  }
  getWLIDDetail(){
    return localStorage.getItem('WLID')
  }
  setSiteName(sitename){
    this.cookieService.set(Site_name, sitename);
  }
}

<footer class=" footer">
  <div class=" container-fluid">
    <ul class=" nav">
      <li class=" nav-item">
        <a class=" nav-link" href="#">
           VRNL
        </a>
      </li>
      <!-- <li class=" nav-item">
        <a class=" nav-link" href="#">
          About Us
        </a>
      </li>
      <li class=" nav-item">
        <a class=" nav-link" href="#"> Blog </a>
      </li> -->
    </ul>
    <div class=" copyright">
      &copy; {{ test | date: "yyyy" }} VRNL, Inc. All rights reserved.
      
     
    </div>
  </div>
</footer>

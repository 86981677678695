let siteName = 'bety247';
let WLID = '2663463';
let hostname = window.origin;
let origin = 'play25.live';
let AgentListLink = '';

if (hostname.indexOf('panel.play25.live') > -1) {
  siteName = 'play25';
  WLID = '2663463'
  origin = 'play25.live'
  AgentListLink = 'https://playagentlist.com/'
}
if (hostname.indexOf('panel.vrnlofficial.com') > -1) {
  siteName = 'vrnlofficial';
  WLID = '1601277'
  origin = 'vrnlofficial.com'
  AgentListLink = 'https://vrnlofficial.com/'
}
if (hostname.indexOf('panel.bety247.info') > -1) {
  siteName = 'bety247';
  WLID = '1866687'
  origin = 'bety247.info'
  AgentListLink = 'https://bety247.info/'
}

export const environment = {
  production: true,
  siteName: siteName,
  hostname:hostname,
  origin:origin,
  WLID:WLID,
  AgentListLink:AgentListLink
};
